import React from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import { config } from '../../firebase';
import { getAuth } from "firebase/auth";

export default class Tournaments extends React.Component {

  constructor(props){
    super(props);
  }

  state = {
    currentUser: null
  }

  setClub = (c) => {
    console.log("setting:", c);
  }

  componentDidMount(){
    this.getData()
  }

  getData = async () => {

    const tok = await firebase.auth().currentUser.getIdToken();

    console.log("tok:", tok);
  }

  render(){

    return(

      <Container fluid className="align-items-center justify-content-center">

        <FirebaseAuthProvider firebase={firebase} {...config}>
          <div style={{ marginTop:'80px'}}>

            <IfFirebaseAuthed>
            {({ isSignedIn, user, providerId }) => {

              this.getData();

              return (

                <div className="heading" style={{marginTop:'80px'}}>

                  <h2>Tournaments</h2>

                  <button
                    aria-label="Increment value"
                    onClick={() => this.setClub('ANOTHER')}
                  >
                    Another
                  </button>


                </div>
              );
            }}
            </IfFirebaseAuthed>

          </div>
        </FirebaseAuthProvider>

      </Container>

    );

  }

}

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import moment from 'moment';
import BallPath from './BallPath';

export default class GameDetail extends React.Component {

  constructor(props){
    super(props);
  }
  state = {
    gameDetails:null
  };
  componentDidMount(){
    //console.log("get data for game id:" + this.props.gameId);
    this.getGameDetails();
    this.getGamePutts();
  }
  getGameDetails = () => {
    this.setState({gameDetails:null});

    const url = "https://api.puttr.co/club/analytics/game/" + this.props.gameId;

    axios.get(url)
      .then(res => {
        this.setState({ gameDetails: res.data[0].game });
      })
  }
  getGamePutts = () => {
    this.setState({gamePutts:null});

    const url = "https://api.puttr.co/club/analytics/putts/" + this.props.gameId;

    axios.get(url)
      .then(res => {
        //console.log("data:", res.data);
        this.setState({ gamePutts: res.data });
      })
  }
  getMakeProbability = (ballPath, holeBounds) => {//get probability that the given path and bounds of hole was a make

    let c = 0;
    let ct = 10;
    const holeCenterX = holeBounds[0];
    const holeCenterY = holeBounds[1];
    const holeRadius = (holeBounds[2] / 2) + 20;

    //see if path at least 10 points, if not set to lower number
    if (ballPath.length < 10){
      ct = ballPath.length; }

    for (let i = ballPath.length - 1; i >= ballPath.length - ct ; i--) {//go through last 10 points in path

      const a = holeCenterX - ballPath[i][0];// a
      const b = holeCenterY - ballPath[i][1];// b

      const rad = Math.sqrt( (a*a) + (b*b) );

      if (rad < holeRadius){//this is the first within the radius of the hole
        c++;
      }
      else{
        break;
      }
    }
    return c;
  }
  greenPressed = (ballPath) => {

    const holeBounds = [320,100,110];

    let bp = JSON.parse(ballPath);
    let mp = this.getMakeProbability(bp, holeBounds);

    console.log("BP:", JSON.stringify(bp));

    navigator.clipboard.writeText(ballPath)
  }
  renderGameDetails = () => {

    if (this.state.gameDetails){

      const gd = this.state.gameDetails;

      console.log("game:", gd);

      let t = moment(gd.created_at).format('MMM Do, hh:mm a');

      let stat = "PENDING";
      if (gd.status == 1)
        stat = "ABANDONED";
      else if (gd.status == 2)
        stat = "COMPLETED";

      return(

        <div>

          <h2>{gd.title}</h2>

          <div>Players: {gd.players}</div>

          <div>{t}</div>

          <div>{stat}</div>

          <div>{gd.clubId}</div>

        </div>
      );
    }
  }

  isBall = (ballPath) => {//function to determine if given path is a ball


    //RADIUS CHECK
    //see if ball run under hole
    let max = 0;
    let min = 500;

    for (let i = 0; i < ballPath.length; i++) {//get min and max radius
      let rad = ballPath[i][2];
      if (rad > max){
        max = rad;
      }
      if (rad < min){
        min = rad;
      }
    }

    if (max > 60){//check if max between 5 and 30
      return false;
    }
    if (max < 15){
      return false;
    }

    //console.log("this was a putt");
    return true;

  }

  renderGamePutts = () => {

    if (this.state.gamePutts){

      return this.state.gamePutts.map((putt, index) => {

        const isb = this.isBall(JSON.parse(putt.ballPath));
        let isBall = "NO";
        if (isb){
          isBall = "YES";
        }

        let t = "LEFT";
        if (putt.tee == 2)
          t = "CENTER";
        else if (putt.tee == 3)
          t = "RIGHT";

        return (

          <div key={index}>

            <div style={{width:'320px', marginRight:'5px'}}
              onClick={() => {this.greenPressed(putt.ballPath)}}
            >

              <BallPath putt={putt} width={320} />

            </div>

            <div style={{alignItems:'center', justifyContent:'center'}}>

              <b>{putt.distance}</b>  feet, <b>{t}</b>  tee

            </div>

          </div>

        );

      });
    }
  }
  render(){

    return(

      <Container fluid style={{backgroundColor:'#F4F4F4'}} className="align-items-center justify-content-center">

        <div style={{marginTop:'80px'}} className="row">

          {this.renderGameDetails() }

        </div>

        <div style={{marginTop:'20px'}} className="row">

        {this.renderGamePutts()}

        </div>

      </Container>

    );

  }

}

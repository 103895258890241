import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import { config } from '../firebase';

import logo from '../images/logo_dark.svg';

function NavBar() {

  return (

    <Navbar collapseOnSelect fixed="top" expand="lg" bg="light" variant="light">

      <Container>

        <Navbar.Brand href="/">

          <img src={logo} width="150" height="40" className="d-inline-block align-top" alt="Puttr logo" />

        </Navbar.Brand>

      </Container>

    </Navbar>

  );
}

export default NavBar;

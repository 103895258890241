import React from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Link } from "react-router-dom";

export default class Admin extends React.Component {

  constructor(props){
    super(props);
  }
  state = {
    clubId: null,
    clubStats: null,
    startDate: null,
    endDate: null
  };
  componentDidMount(){
    var d2 = new Date();
    var d1 = new Date(new Date().setDate(d2.getDate() - 1));
    this.setState({startDate: d1, endDate:d2});
    setTimeout(this.getReportData,100);
  }
  getReportData = () => {
    this.getClubGames();
  }
  addLeading = (num) => {
    if (num < 10){
      return "0" + num;
    }
    else {
      return num;
    }
  }
  getDateString = () => {
    let dt_str = "";
    if (this.state.startDate){
      let dt1 = this.state.startDate;//these times are in local time, convert to utc before sending
      let dt2 = this.state.endDate;

      let d1 = dt1.getFullYear() + "-" + this.addLeading(dt1.getMonth()+1) + "-" + this.addLeading(dt1.getDate());
      let d2 = dt2.getFullYear() + "-" + this.addLeading(dt2.getMonth()+1) + "-" + this.addLeading(dt2.getDate());
      dt_str = "?date1="+ d1 + "&date2="+d2;
    }

    return dt_str;

  }
  getClubGames = async() => {
    this.setState({clubGames:null});

    let url = "https://api.puttr.co/club/analytics/games/";

    if (this.state.clubId){
      url += this.state.clubId;
    }

    url += this.getDateString();
    console.log("cc:", url);
    axios.get(url)
      .then(res => {
        //console.log("data:", res.data);
        this.setState({ clubGames: res.data });
      })
  }
  setStartDate = (dt) => {
    this.setState({startDate: dt});
    setTimeout(this.getReportData,100);
  }
  setEndDate = (dt) => {
    this.setState({endDate: dt});
    setTimeout(this.getReportData,100);
  }
  renderCalendar = () => {
    if (this.state.startDate){
      return(

        <div className="row">

          <div className="datePicker">

            FROM:

            <DatePicker
              selected={this.state.startDate}
              onChange={(date:Date) => this.setStartDate(date)}
              wrapperClassName="datePicker"
            />

          </div>

          <div className="datePicker">

          TO:

          <DatePicker
            selected={this.state.endDate}
            onChange={(date:Date) => this.setEndDate(date)}
            wrapperClassName="datePicker"
          />

          </div>

        </div>


      );
    }
  }
  renderUniqueGames = () => {
    if (this.state.clubStats){

      return this.state.clubStats.gamesPlayed.map((game, index) => {

        return(

          <div key={index} className="lbRow">

            {game.title}: {game.total}

          </div>

        )

      });

    }
  }
  renderClubStats = () => {

    if (this.state.clubStats){

      const cs = this.state.clubStats;

      const min = Math.round(cs.secondsPlayed / 60);
      const made = cs.madePutts;
      const missed = cs.putts - made;
      const perc = (made / cs.putts) * 100;

      return(

        <div style={{marginTop:'20px'}} className="row">

          <div className="lbRow"><b>{cs.games}</b> GAMES </div>
          <div className="lbRow"><b>{cs.putts}</b> TOTAL PUTTS</div>
          <div className="lbRow"><b>{made}</b> MADE PUTTS</div>
          <div className="lbRow"><b>{missed}</b> MISSED PUTTS</div>
          <div className="lbRow"><b>{perc.toFixed(2)}</b> MAKE %</div>
          <div className="lbRow"><b>{min}</b> MINUTES PLAYED</div>
        </div>
      );
    }

    else {
      return(
        <div>LOADING...</div>
      );
    }
  }
  copyEmail = (e) => {
    navigator.clipboard.writeText(e);
  }
  renderClubGames = () => {

    if (this.state.clubGames){

      return this.state.clubGames.map((game, index) => {

        let t = moment(game.created_at).format('MMM Do, hh:mm a');

        let link = "/admin/game/" + game.id;
        let userLink = "/admin/user/" + game.userId;

        let stat = "PENDING";
        if (game.status == 1)
          stat = "ABANDONED";
        else if (game.status == 2)
          stat = "COMPLETED";

        return (

            <div key={index} className="lbRow">

              <div>

                <a href={link} target="_blank">

                  <div style={{fontSize:'12pt'}}>{game.title}</div>

                </a>

                <div style={{fontSize:'8pt'}}>{t}</div>

                <div style={{fontSize:'8pt', fontWeight:'bold'}}>{game.clubId}</div>

              </div>

              <div style={{marginLeft:'10px'}}>

                <a href={userLink} target="_blank">

                  <div style={{fontSize:'8pt', fontWeight:'bold'}}>{game.displayName}</div>

                </a>

                <div style={{fontSize:'8pt'}}>{stat}</div>

                <div style={{fontSize:'8pt'}}>{game.players} players</div>

                <div onClick={ () => { this.copyEmail(game.email) } } style={{fontSize:'8pt', fontWeight:'bold'}}>{game.email}</div>

              </div>

            </div>



        );

      });

    }

  }
  setClubId = (clubId) => {
    //console.log("set club:", clubId);
    this.setState({ clubId: clubId });

  }
  render(){

    return(

      <Container fluid className="align-items-center justify-content-center">

        <div className="heading" style={{marginTop:'80px'}}>

          <div className="row">

            <input
              type="text" id="clubId" name="clubId" class="form-control"
              value={this.state.clubId}
              onChange={e => this.setClubId(e.target.value)}
              required
            />

            <div class="text-center">
              <a className="btn btn-success" onClick={this.getReportData}>SUBMIT</a>
            </div>

          </div>

          {this.renderCalendar()}

          {this.renderClubGames()}

        </div>

      </Container>

    );

  }

}

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BallPath from './BallPath';
import UserRow from './UserRow';
import GameCard from './GameCard';

export default class UserGameView extends React.Component {

  constructor(props){
    super(props);
  }
  state = {
    gameDetails:null,
    gamePutts:null,
    gameLeaderboard:null
  };

  componentDidMount(){
    this.getGameDetails();
    this.getGamePutts();
  }

  getGameDetails = () => {
    this.setState({gameDetails:null});

    const url = "https://api.puttr.co/club/analytics/game/" + this.props.gameId;

    axios.get(url)
      .then(res => {
        this.setState({ gameDetails: res.data });
        console.log("game data:", res.data);

        const id = res.data[0].game.gameId;
        this.getGameLeaderboard(id);
      })
  }
  getGameLeaderboard = (gameId) => {

    this.setState({gameLeaderboard:null});

    const url = "https://api.puttr.co/club/leaderboard/PUTTR/" + gameId + "/0";

    console.log("url:", url);

    axios.get(url)
      .then(res => {
        //console.log("lb data:", res.data);
        this.setState({ gameLeaderboard: res.data });
      })
  }
  getGamePutts = () => {
    this.setState({gamePutts:null});

    const url = "https://api.puttr.co/club/analytics/putts/" + this.props.gameId;

    axios.get(url)
      .then(res => {
        console.log("data:", res.data);
        this.setState({ gamePutts: res.data });
      })
  }
  renderGameDetails = () => {

    if (this.state.gameDetails){

      const gd = this.state.gameDetails[0].game;
      const u = this.state.gameDetails[1].user;
      const m = moment(gd.created_at, "YYYY-MM-DD").fromNow();

      return(

        <div className="center align-items-center justify-content-center" style={{height:'150px'}}>

          <div className="gameIcon">

            <FontAwesomeIcon color="green" icon={gd.icon} />

          </div>

          <div className="title">{gd.title}</div>

          <div>{gd.players} player</div>

          <div className="timeAgo">{m}</div>

          <div style={{marginTop:'10px'}}>

            <UserRow user={u} />

          </div>

        </div>
      );
    }
  }
  renderGamePutts = () => {

    if (this.state.gamePutts){

      return this.state.gamePutts.map((putt, index) => {

        let t = "LEFT";
        if (putt.tee == 2)
          t = "CENTER";
        else if (putt.tee == 3)
          t = "RIGHT";

        //console.log("made??" + putt.made);

        return (

          <div key={index} style={{alignItems:'center', justifyContent:'center'}}>

            <div style={{width:'320px', marginLeft:'5px', marginRight:'5px', alignItems:'center', justifyContent:'center'}}>

              <BallPath ballPath={putt.ballPath} putt={putt} width={300} />

            </div>

            <div className="puttLocation">

              <b>{putt.distance}</b>&nbsp;feet, <b>{t}</b>&nbsp;tee

            </div>

          </div>

        );

      });
    }
  }
  renderGameCard = () => {

    if (this.state.gameDetails && this.state.gameLeaderboard && this.state.gamePutts){

      return(
        <GameCard
          leaderboard={this.state.gameDetails[0].game}
          scores={this.state.gameLeaderboard}
          user={this.state.gameDetails[1].user}
          putts={this.state.gamePutts}
        />
      );

    }

    return(
      <div style={{height:'500px'}} className="align-items-center justify-content-center">

        <h3>LOADING GAME DETAILS</h3>

      </div>
    );

  }

  render(){

    return(

      <Container fluid style={{backgroundColor:'#F4F4F4'}} className="align-items-center justify-content-center">

        <div style={{marginTop:'60px'}} className="row">

          <div className="center" style={{marginTop:'20px'}}>

            {this.renderGameCard()}

          </div>

        </div>

        <div className="title center">PUTTS</div>

        <div style={{marginTop:'20px'}} className="prow">

            {this.renderGamePutts() }

        </div>

      </Container>

    );

  }

}

import React from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import logo from '../images/logo_dark.svg';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import { config } from '../firebase';
const uiConfig = {
  signInFlow: 'popup',// Popup signin flow rather than redirect flow.
  signInSuccessUrl: '/',
  signInOptions: [// We will display Google and Facebook as auth providers.
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],
};

export default class Home extends React.Component {

  state = {
    user:'',
  };

  constructor(props){
    super(props);
  }

  renderStat(num, text){
    return(
      <div style={{padding:'5px'}}>
        <div className="gameScore">{num.toLocaleString()}</div>
        <div className="description">{text}</div>
      </div>
    );
  }

  getUserProfile = () => {

  }

  renderUser = () => {
    if (this.props.user){
      console.log("USER!!" + this.props.user);
    }
    else {
      console.log("no user");
    }
  }

  renderHomeStats = () => {

    const p = 10000;
    const g = 1000;

    return (
      <div className="center">

        <div style={{marginTop:'20px'}} className="row">

          {this.renderUser()}
          {this.renderStat(p,"PUTTS")}
          {this.renderStat(g,"GAMES")}

        </div>

      </div>
    );

  }

  render(){

    return(

      <Container fluid className="align-items-center justify-content-center">

        <FirebaseAuthProvider firebase={firebase} {...config}>
          <div style={{ marginTop:'80px'}}>

            <IfFirebaseUnAuthed>

              <div className="heading">
              <img src={logo} style={{width:200}} alt="logo" />
              <p>
                Welcome to the Puttr Admin page!
              </p>
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />

              </div>
            </IfFirebaseUnAuthed>

            <IfFirebaseAuthed>
            {({ isSignedIn, user, providerId }) => {
              return this.renderHomeStats();
            }}
            </IfFirebaseAuthed>

          </div>
        </FirebaseAuthProvider>

      </Container>

    );

  }

}

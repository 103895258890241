import React from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';

import axios from 'axios';
import moment from 'moment';
import BallPath from '../game/BallPath';

class UserDetail extends React.Component {

  constructor(props){
    super(props);
  }

  state = {
    user: null,
    userPutts: null,
    userDevices: null
  }

  componentDidMount(){

    this.getUserPutts();
    this.getUserDevices();
  }

  getUserPutts = () => {
    this.setState({userPutts:null});

    const url = "https://api.puttr.co/club/analytics/user/" + this.props.userId;

    axios.get(url)
      .then(res => {
        //console.log("data:", res.data);
        this.setState({ user: res.data.user[0], userPutts: res.data.userPutts });
      })
  }

  getUserDevices = () => {
    this.setState({userDevices:null});

    const url = "https://api.puttr.co/club/analytics/userDevices/" + this.props.userId;

    axios.get(url)
      .then(res => {
        //console.log("data:", res.data);
        this.setState({ userDevices: res.data });
      })
  }

  renderUserDevices = () => {

    if (this.state.userDevices){

      return this.state.userDevices.map((device, index) => {

        let d = JSON.parse(device.deviceInfo);

        return (

          <div key={index}>

            <div style={{alignItems:'center', justifyContent:'center'}}>

              <b>{d.model}</b> - {d.brand}

            </div>

          </div>

        );

      });

    }

  }

  renderUser = () => {

    if (this.state.user){

      let u = this.state.user;

      return (

        <div style={{flexDirection:'row'}}>

            <b>{u.displayName}</b> -

            {u.email} -

            {u.userId}

        </div>

      );

    }

  }

  renderUserPutts = () => {

    if (this.state.userPutts){

      return this.state.userPutts.map((putt, index) => {

        if (index < 100){

          let tm = moment(putt.created_at).format('MM-DD hh:mm:ss');

          let t = "LEFT";
          if (putt.tee == 2)
            t = "CENTER";
          else if (putt.tee == 3)
            t = "RIGHT";

          return (

            <div key={index}>

              <div style={{width:'320px', marginRight:'5px'}}>

                <BallPath putt={putt} width={320} />

              </div>

              <div style={{alignItems:'center', justifyContent:'center'}}>

                <b>{putt.distance}</b>  feet, <b>{t}</b>  tee - {tm}

              </div>

            </div>

          );

        }



      });
    }
  }

  render(){

    return(

      <Container fluid className="align-items-center justify-content-center">

          <div className="heading" style={{marginTop:'80px'}}>

            {this.renderUser()}

            {this.renderUserDevices()}

            {this.renderUserPutts()}

          </div>


      </Container>

    );

  }

}

const mapStateToProps = state => {
  return {
    app: state.app,
  }
};

export default connect(mapStateToProps, null)(UserDetail);

import React from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import axios from 'axios';
import { connect } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import { config } from '../../firebase';

import BallPath from '../game/BallPath';

export default class Holes extends React.Component {

  constructor(props){
    super(props);
  }

  state = {
    holes: null
  };

  componentDidMount(){
    this.getHoles();
  }

  getHoles = async() => {
    this.setState({holes:null});

    const url = "http://localhost:8080/admin/holeBounds";
    axios.get(url)
      .then(res => {
        this.setState({ holes: res.data });
      })
  }

  checkHolePosition = (hb) => {

    if (hb){
      let h = JSON.parse(hb);
      //console.log("checking:", h[0])
      const x = h[0];
      const y = h[1];
      const r = h[2];

      const xMin = 270;
      const xMax = 370;

      const rMin = 80;
      const rMax = 130;

      if ( (x < xMin) || (x > xMax) ) {
        return 0;
      }

      else if ( (r < rMin) || (r > rMax) ) {
        return 0;
      }

    }
    return 1;

  }

  renderHoles = () => {

    if (this.state.holes){

      return this.state.holes.map((hole, index) => {

        let hb = hole.holeBounds;
        let m = this.checkHolePosition(hb);

        const putt = {
          holeBounds: hb,
          made:m
        }

        if (hb){

          return (

            <div key={index}>

              <div style={{width:'320px', marginRight:'5px'}}>

                <BallPath ballPath={[]} putt={putt} width={320} />

              </div>

              <div style={{alignItems:'center', justifyContent:'center'}}>

                <b>{index} - {hb}</b>

              </div>

            </div>

          );

        }

      });
    }
  }

  render(){

    return(

      <Container fluid className="align-items-center justify-content-center">

        <FirebaseAuthProvider firebase={firebase} {...config}>
          <div style={{ marginTop:'80px'}}>

            <IfFirebaseAuthed>
            {({ isSignedIn, user, providerId }) => {
              return (

                <div className="heading" style={{marginTop:'80px'}}>

                  <h2>HOLES</h2>

                  <div style={{marginTop:'20px'}} className="row">

                    {this.renderHoles()}

                  </div>

                </div>
              );
            }}
            </IfFirebaseAuthed>

          </div>
        </FirebaseAuthProvider>

      </Container>

    );

  }

}

import React from 'react';
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class LeaderboardCard extends React.Component {

  constructor(props){
    super(props);
  }
  getPlaceText = (place) => {
    let str = place;
    if (place == 1)
      str += "st";
    if (place == 2)
      str += "nd";
    if (place == 3)
      str += "rd";
    else if (place > 3)
      str += "th";
    return str;
  }
  renderAvatar = (user) => {
    if (user.photoURL){
      return(
        <div className="avatar">
          <Image roundedCircle src={user.photoURL} width="30" height="30" alt="Photo" />
        </div>
      );
    }

    else {
      const i = user.displayName.substring(0,1);
      return(
        <div className="avatar"> {i} </div>
      );

    }
  }
  renderScores = (scores) => {

    if (scores.length == 0)
      return <div className="title">NO SCORES YET</div>

    let ct = 0;
    let howMany = 5;
    if (this.props.fullBoard)
      howMany = 1000;

    return scores.map((score, index) => {

      if (ct++ < howMany){

        const user = JSON.parse(score.userName);
        const pl = this.getPlaceText(index+1);

        return(

          <div key={index} className="lbRow">

            <div className="lbPlace">{pl}</div>

            {this.renderAvatar(user)}

            <div className="lbDisplayName">{user.displayName}</div>

            <div className="lbScore">{score.score}</div>

          </div>

        );

      }

    });
  }

  renderBackLink = () => {

    if ((this.props.fullBoard) && (!this.props.signage) ) {
      return(
        <Link to="#" onClick={() => {this.props.selectLeaderboard(null)}}>

          <div style={{fontSize:'10pt', padding:'10px', color:'#333'}}>GO BACK > </div>

        </Link>
      );
    }

  }
  renderMoreLink = (lb) => {

    if ( (this.props.signage != 1) && (!this.props.fullBoard) ) {

      const ll = "/leaderboard/" + this.props.clubId + "/" + lb.id;

      return (

        <Link to="#" onClick={() => {this.props.selectLeaderboard(this.props.leaderboard)}}>

          <div style={{fontSize:'10pt', padding:'10px', color:'#333'}}>VIEW FULL LEADERBOARD > </div>

        </Link>

      );
    }


  }
  render(){

    const lb = this.props.leaderboard.leaderboard;
    const sc = this.props.leaderboard.scores;

    return(

      <div key={lb.id} className="lbCard">

        <div className="lbIcon">

          <FontAwesomeIcon color="green" icon={lb.icon} />

        </div>

        <div className="lbTitle">{lb.title}</div>

        {this.renderBackLink()}

        <div className="justify-content-start">

          {this.renderScores(sc)}

        </div>

        {this.renderMoreLink(lb)}

        {this.renderBackLink()}

      </div>


    );

  }

}

import React from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import logo from '../../images/logo_dark.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlassCheers } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { PaymentElement, ElementsConsumer } from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe("pk_test_51ItZzZBaUtVSMENSb9JQIw0wmFP7HpdUTzkP55PDICk2jxeKtmrfLQT8zVlpPmdwiqurNCKgJOXhpjUyRUAyVtR10068NvljI4");
//const stripePromise = loadStripe("pk_live_51ItZzZBaUtVSMENSEdxi7gyeOoAU8Bm1jov7XZzKlEw4nMjwYN5A93hwBR7cM2HQKxEWnRTXlqNMnaPNni355n9c00CNhCUxUK");

const API_URL = "https://api.puttr.co";
//const API_URL = "http://localhost:8080";


export default class LanePay extends React.Component {

  constructor(props){
    super(props);

  }
  state = {
    loading:false,
    registered: false,
    laneInfo: null,
    total: 10,
    clientSecret:null,
    quantity:1,
    perHour:0
  }
  componentDidMount(){
    this.getLaneInfo();
  }

  getPaymentSecret = async () => {

    this.setState({payLoading:true});

    const url = API_URL + "/web/createPaymentIntent";
    const newTot = Math.round(this.state.total * 100);

    const params = {
      total: newTot,
      description: "LANE PAYMENT FOR LANE " + this.props.laneId,
      metadata: {
        laneId: this.props.laneId,
        clubId: this.state.laneInfo.clubId
      }
    }

    await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    })
    .then((res) => res.json())
    .then((data) => {
      this.setState({clientSecret: data.client_secret })
    });

    this.setState({payLoading:false});
  }
  getLaneInfo = async() => {
    this.setState({loading:true});

    const url = API_URL + '/club/locations/lane/' + this.props.laneId;

  	axios.get(url)
    .then(request => {
      console.log("lane info", request.data[0]);
      this.setState({
        laneInfo:request.data[0],
        perHour:request.data[0].perHour
      });
      this.getPaymentSecret();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  renderLaneInfo = () => {
    if (this.state.laneInfo){

      const lane = this.state.laneInfo;

      return(

        <div className="title">{lane.welcomeMessage}</div>
      );
    }
  }
  changeQuantity = (quan) => {
    const t = quan * this.state.perHour;
    this.setState({
      quantity: quan,
      total: t
    });
  }
  renderQuantity = () => {
    return(

      <div className="center" style={{marginTop:10}}>

        <div className="description">HOW MANY HOURS WOULD YOU LIKE TO PUTT?</div>

        <div style={{width:100, marginTop:5}}>

          <Form.Select aria-label="QUANTITY" id="quantity" name="quantity" class="form-control"
            value={this.state.quantity}
            onChange={e => this.changeQuantity(e.target.value)}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>

          </Form.Select>

        </div>

      </div>
    );
  }
  renderButton = () => {
    if (this.state.registerLoading){
      return(
        <h4>REGISTERING...</h4>
      );
    }

    if (this.state.registered){
      return(
          <>
          <FontAwesomeIcon icon={faGlassCheers} color="green" size="lg" />
          <h3 color='green'>CHEERS!</h3>
          <p>You should see your name on the screen, press it to select as player.</p>
          </>
      );
    }

    return (
      <Button variant="success" onClick={this.registerLaneButton}>REGISTER</Button>
    );
  }
  renderPayInfo = () => {

    if (this.state.clientSecret){

      const clientSecret = this.state.clientSecret;
      const appearance = {
        theme: 'stripe',
      };
      const options = {
        clientSecret,
        appearance,
      };

      return(

        <div style={{marginTop:10}}>

          <Elements stripe={stripePromise} options={options}>

          <ElementsConsumer>
            {({stripe, elements}) => (

              <CheckoutForm stripe={stripe} elements={elements} total={this.state.total} laneId={this.props.laneId} options={options} />

            )}
          </ElementsConsumer>

          </Elements>

        </div>

      );

    }
  }
  render(){

    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("redirect_status");

    if (c == "succeeded"){
      return(

        <div className="center" style={{marginTop:'80px'}}>

          <div style={{fontSize:80}}>

            <FontAwesomeIcon icon={faGlassCheers} color="green" />

          </div>

          <div className="title">ALL SET!</div>

          <p>Your PUTTR should now be enabled</p>

          <p>Any questions? Contact us at <a href="mailto:info@puttr.co">info@puttr.co</a></p>

        </div>

      );
    }

    return(

      <Container fluid className="align-items-center justify-content-center">

          <div style={{marginTop:'70px'}}>

            <header className="heading">

            {this.renderLaneInfo()}

            {this.renderQuantity()}

            {this.renderPayInfo()}

            </header>

          </div>

      </Container>

    );

  }

}

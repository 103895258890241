import React from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import logo from '../../images/logo_dark.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlassCheers } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import { config } from '../../firebase';
let uiConfig;
const API_URL = "https://api.puttr.co";

firebase.initializeApp(config);

export default class LaneRegister extends React.Component {

  constructor(props){
    super(props);

    uiConfig = {
      signInFlow: 'popup',// Popup signin flow rather than redirect flow.
      signInSuccessUrl: '/club/register/lane/' + props.laneId,
      signInOptions: [// We will display Google and Facebook as auth providers.
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: (authResult) => this.registerNewUser(authResult),
      },
    };
  }
  state = {
    registerLoading:false,
    registered: false,
    user:'',
    profile:''
  }
  componentDidMount(){
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {

      if (user){
        this.setState({user:user});
        //get profile
        this.getUserProfile();
      }

    });
  }
  getUserProfile = async () => {
    const tok = await firebase.auth().currentUser.getIdToken();
    const url = API_URL + '/profile/';
    const opts = { headers: { Authorization: `Bearer ${tok}`} };

  	axios.get(url, opts)
    .then(request => {
      this.setState({profile:request.data[0]})
    })
    .catch(function (error) {
      console.log("Fetch profile error:", error);
    });
  }
  registerNewUser = (user) => {

    const url = API_URL + '/profile/createSocial';

    if (user.additionalUserInfo.isNewUser){

      let pic = '';
      if (user.user.photoURL){
        pic = user.user.photoURL;
      }

      const params = {
        userId: user.user.uid,
        email: user.user.email,
        displayName: user.user.displayName,
        photoURL: pic,
        accountType: user.user.providerId,
        source: 'WEB'
      };

      axios.post(url, params)
      .then(function (response) {
        window.location.reload();
        console.log("response", response);
      })
      .catch(function (error) {
        console.log(error);
      });

    }
    else {
      window.location.reload();
    }
  }
  renderProfile = () => {
    if (this.state.profile){
      console.log("profile:", this.state.profile);
      const pic = this.state.profile.photoURL;
      const name = this.state.profile.displayName;
      return(
        <div>
          <h3>Hey {name}</h3>
        </div>
      );
    }
    else {
      return(
        <div>LOADING...</div>
      );
    }
  }
  registerLaneButton = async() => {
    this.setState({registerLoading:true});
    const tok = await firebase.auth().currentUser.getIdToken();

    const url = API_URL + '/club/locations/lanes/register/' + this.props.laneId;
    const opts = { headers: { Authorization: `Bearer ${tok}`} };

  	axios.get(url, opts)
    .then(request => {
      this.setState({registerLoading:false, registered:true});
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  renderButton = () => {
    if (this.state.registerLoading){
      return(
        <h4>REGISTERING...</h4>
      );
    }

    if (this.state.registered){
      return(
          <>
          <FontAwesomeIcon icon={faGlassCheers} color="green" size="lg" />
          <h3 color='green'>CHEERS!</h3>
          <p>You should see your name on the screen, press it to select as player.</p>
          </>
      );
    }

    return (
      <Button variant="success" onClick={this.registerLaneButton}>REGISTER</Button>
    );
  }
  render(){

    return(

      <Container fluid className="align-items-center justify-content-center">

        <FirebaseAuthProvider firebase={firebase} {...config}>
          <div style={{marginTop:'100px'}}>
            <header className="heading">

              <IfFirebaseUnAuthed>
                <img src={logo} style={{width:200}} alt="logo" />
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
              </IfFirebaseUnAuthed>

              <IfFirebaseAuthed>
              {({ isSignedIn, user, providerId }) => {
                return (
                  <div className="center">
                    {this.renderProfile()}

                    <p>Are you sure you want to sign in to this PUTTR+ lane?</p>

                    {this.renderButton()}

                  </div>
                );
              }}
              </IfFirebaseAuthed>
            </header>
          </div>
        </FirebaseAuthProvider>

      </Container>

    );

  }

}

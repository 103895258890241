import React from 'react';
import Image from 'react-bootstrap/Image';
import { Link } from "react-router-dom";
import moment from 'moment';
import Container from 'react-bootstrap/Container';
import Badge from 'react-bootstrap/Badge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import UserRow from './UserRow';

export default class GameCard extends React.Component {

  constructor(props){
    super(props);
  }
  getPlaceText = (place) => {
    let str = place;
    if (place == 1)
      str += "st";
    if (place == 2)
      str += "nd";
    if (place == 3)
      str += "rd";
    else if (place > 3)
      str += "th";
    return str;
  }
  renderAvatar = (user) => {
    if (user.photoURL){
      return(
        <div className="avatar">
          <Image roundedCircle src={user.photoURL} width="40" height="40" alt="Photo" />
        </div>
      );
    }

    else {
      const i = user.displayName.substring(0,1);
      return(
        <div className="avatar"> {i} </div>
      );

    }
  }
  getPercentage = () => {

    const gp = this.props.putts;
    const tot = gp.length;
    let ct = 0;


    for (var i=0;i<gp.length;i++){
      if (gp[i].made == 1){
        ct++;
      }
    }

    const p = Math.round( (ct / tot) * 100);
    return p;

  }
  renderStat(num, text){
    return(
      <div style={{padding:'5px'}}>
        <div className="gameScore">{num}</div>
        <div className="description">{text}</div>
      </div>
    );
  }
  renderPuttOverview = () => {

    const tot = this.props.putts.length;
    const score = this.props.leaderboard.score;
    const perc = this.getPercentage();

    return(

      <div className="prow">

        {this.renderStat(score, 'score')}

        {this.renderStat(tot, 'total putts')}

        {this.renderStat(perc + "%", 'made')}

      </div>

    );
  }

  renderUserScore = () => {

    const user = this.props.user;
    const score = this.props.leaderboard.score;
    const sc = this.props.scores;
    let place = 1;
    for (var i=0;i< sc.length;i++){
      //compare player score to current leaderboard
      if (score == sc[i].score){
        place = i;
        break;
      }
    }

    const pl = this.getPlaceText(place+1);

    return(

      <div className="lbRowSelected">

        <div className="lbPlace">{pl}</div>

        {this.renderAvatar(user)}

        <div className="lbDisplayName">{user.displayName}</div>

        <div className="lbScore">{score}</div>

      </div>

    );
  }
  renderScores = (scores) => {

    if (scores.length == 0)
      return <div className="title">NO SCORES YET</div>

    let ct = 0;
    let howMany = 3;

    return scores.map((score, index) => {

      if (ct++ < howMany){

        const user = JSON.parse(score.userName);
        const pl = this.getPlaceText(index+1);

        return(

          <div key={index} className="lbRow">

            <div className="lbPlace">{pl}</div>

            {this.renderAvatar(user)}

            <div className="lbDisplayName">{user.displayName}</div>

            <div className="lbScore">{score.score}</div>

          </div>

        );

      }

    });
  }

  render(){

    const lb = this.props.leaderboard;
    const sc = this.props.scores;
    const u = this.props.user;
    const gp = this.props.putts;

    const m = moment(lb.created_at, "YYYY-MM-DD").fromNow();

    return(

      <div key={lb.id}>

      <div className="lbCard">

        <div className="lbIcon">

          <FontAwesomeIcon color="green" icon={lb.icon} />

        </div>

        <div className="lbTitle">{lb.title}</div>

        <UserRow user={u} />

        <div className="timeAgo" style={{marginTop:'10px'}}>{m}</div>

        <div style={{marginTop:'10px'}} className="justify-content-start">

          {this.renderPuttOverview()}

        </div>

      </div>

      <div className="lbCard">

        <div className="lbTitle" style={{marginTop:'20px'}}>LEADERBOARD</div>

        <div style={{marginTop:'5px'}} className="justify-content-start">

          {this.renderScores(sc)}

          <div className="center">...</div>

          {this.renderUserScore()}

        </div>

      </div>

      </div>


    );

  }

}

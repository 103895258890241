import React from 'react';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { connect } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed } from "@react-firebase/auth";
import { config } from '../../firebase';

class Users extends React.Component {

  constructor(props){
    super(props);
  }

  render(){

    return(

      <Container fluid className="align-items-center justify-content-center">

        <FirebaseAuthProvider firebase={firebase} {...config}>
          <div style={{ marginTop:'80px'}}>

            <IfFirebaseAuthed>
            {({ isSignedIn, user, providerId }) => {
              return (

                <div className="heading" style={{marginTop:'80px'}}>

                  <h2>USERS</h2>

                  CLUB: {this.props.app.clubId}

                </div>


              );
            }}
            </IfFirebaseAuthed>

          </div>
        </FirebaseAuthProvider>

      </Container>

    );

  }

}

const mapStateToProps = state => {
  return {
    app: state.app,
  }
};

export default connect(mapStateToProps, null)(Users);

const INITIAL_STATE = {
  clubId: 'PUTTR',
}

export default (state = INITIAL_STATE, action) => {

 switch (action.type) {

  case 'SET_CLUB_ID':
    return { ...state, clubId: action.payload };

  default:
   return state

 }
}
